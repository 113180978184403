// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  h1 {
    font-weight: 600;
    font-size: 36px;
    letter-spacing: -2%;
  }

  h2 {
    font-weight: 600;
    font-size: 30px;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
  }

  ion-content > ion-card {
    margin-left: 30px;
  }

  ion-card-header > ion-title {
    h2 {
      color: var(--ion-color-secondary-contrast);
    }

    h5 {
      color: var(--ion-text-color);
    }
  }
  ion-col > ion-label {
    font-weight: 600;
    color: var(--ion-text-color);
  }

  ion-card h1 {
    color: var(--ion-color-primary);
  }
  ion-label > span {
    font-size: 16px;
  }

  ion-breadcrumbs {
    padding-left: 22px;
    padding-top: 8px;
    font-weight: 600;
  }

  .full-header {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    font-weight: 600;
    text-transform: uppercase;
  }

  .card-in-modal {
    margin: 30px 30px 10px 30px;
    + * {
      margin: 10px 30px;
    }
  }

  --ion-text-color: #5A6A85;
  --ion-text-color-rgb: 90, 106, 133;
  --ion-color-contrast: grey;
  /** primary **/
  --ion-color-primary: #367ab6;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #ECF2FF;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #367AB6;
  --ion-color-secondary-contrast-rgb: 54, 122, 182;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #F2F6FA;
  --ion-color-medium-rgb: 242, 246, 250;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #ECF2FF;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #5A6A85;
  --ion-color-light-contrast-rgb: 90, 106, 133;
  --ion-color-light-shade: #ECF2FF;
  --ion-color-light-tint: #f5f6f9;

  --ion-font-family: 'Plus Jakarta Sans';
}

html {
  /*
   * For more information on dynamic font scaling, visit the documentation:
   * https://ionicframework.com/docs/layout/dynamic-font-scaling
   */
  --ion-dynamic-font: var(--ion-default-dynamic-font);
}

@font-face {
  font-family: 'Plus Jakarta Sans';
  src: url('../assets/fonts/plus_jakarta_sans.woff') format('woff');
}
